<template>
  <md-tabs md-dynamic-height>
    <md-tab md-label="Zeiten">
      <div class="grid-x grid-padding-x">
        <div v-if="facilitySettings" class="cell large-6 medium-12 small-12">
          <p><b class="md-title">Buchungszeit-Einstellungen</b></p>
          <br>
          <div class="grid-x grid-padding-x">
            <div class="cell large-6 medium-6 small-12">
              <md-field :class="getValidationClass('timeStepMinutesTimes15')">
                <label>Buchungszeitschritt</label>
                <md-select id="booking-times-steps" v-model="facilitySettings.timeStepMinutesTimes15"
                           autocomplete="off" @md-selected="selectBookingTimesStep"
                           :disabled="sending">
                  <md-option v-for="step of bookingTimesSteps" v-bind:value="step.number" :key="step.number">
                    {{ step.value }}
                  </md-option>
                </md-select>
                <span class="md-error"
                      v-if="!$v.facilitySettings.timeStepMinutesTimes15.required">Buchungszeitschritt ist notwendig</span>
              </md-field>
            </div>
          </div>
          <div class="grid-x grid-padding-x">
            <div class="cell large-6 medium-6 small-12">
              <md-field :class="getValidationClass('bookingTimesStart')">
                <label>Früheste Buchungszeit</label>
                <md-select id="booking-times-start" v-model="facilitySettings.bookingTimesStart"
                           autocomplete="off" @md-selected="validateBookingStartTime"
                           :disabled="sending">
                  <md-option v-for="timeSlot of bookingTimeSlots" v-bind:value="timeSlot" :key="timeSlot">
                    {{ timeSlot }}
                  </md-option>
                </md-select>
                <span class="md-error"
                      v-if="!$v.facilitySettings.bookingTimesStart.required">Früheste Buchungszeit ist notwendig</span>
              </md-field>
            </div>
            <div class="cell large-6 medium-6 small-12">
              <md-field :class="getValidationClass('bookingTimesEnd')">
                <label>Späteste Buchungszeit</label>
                <md-select id="booking-times-end" v-model="facilitySettings.bookingTimesEnd"
                           autocomplete="off" @md-selected="validateBookingEndTime"
                           :disabled="sending">
                  <md-option v-for="timeSlot of bookingTimeSlots" v-bind:value="timeSlot" :key="timeSlot">
                    {{ timeSlot }}
                  </md-option>
                </md-select>
                <span class="md-error"
                      v-if="!$v.facilitySettings.bookingTimesEnd.required">Späteste Buchungszeit ist notwendig</span>
              </md-field>
            </div>
          </div>
          <div class="grid-x grid-padding-x">
            <div class="cell large-6 medium-6 small-12">
              <md-field :class="getValidationClass('coreTimeStart')">
                <label>Kernzeitbegin</label>
                <md-select id="core-time-start" v-model="facility.coreTimeStart"
                           autocomplete="off" @md-selected="validateCoreStartTime"
                           :disabled="sending || coreTimeSlots.length === 0">
                  <md-option v-for="timeSlot of coreTimeSlots" v-bind:value="timeSlot" :key="timeSlot">
                    {{ timeSlot }}
                  </md-option>
                </md-select>
                <span class="md-error"
                      v-if="!$v.facility.coreTimeStart.required">Kernzeitbegin ist notwendig</span>
              </md-field>
            </div>
            <div class="cell large-6 medium-6 small-12">
              <md-field :class="getValidationClass('coreTimeEnd')">
                <label>Kernzeitende</label>
                <md-select id="core-time-end" v-model="facility.coreTimeEnd"
                           autocomplete="off" @md-selected="validateCoreEndTime"
                           :disabled="sending || coreTimeSlots.length === 0">
                  <md-option v-for="timeSlot of coreTimeSlots" v-bind:value="timeSlot" :key="timeSlot">
                    {{ timeSlot }}
                  </md-option>
                </md-select>
                <span class="md-error"
                      v-if="!$v.facility.coreTimeEnd.required">Kernzeitende ist notwendig</span>
              </md-field>
            </div>
          </div>
        </div>
        <div class="cell large-6 medium-12 small-12">
        </div>
      </div>
    </md-tab>
    <md-tab md-label="Bezirksrechnung">
      <div class="grid-x grid-padding-x">
        <div v-if="facilitySettings" class="cell large-12 medium-12 small-12">
          <p><b class="md-title">Bezirksrechnung-Einstellungen</b></p>
          <br>
          <div class="grid-x grid-padding-x">
            <div class="cell large-3 medium-6 small-12">
              <md-field :class="getValidationClass('bezirkZENummer')">
                <label>Bezirk ZE-Nummer</label>
                <md-input name="bezirkZENummer" id="bezirkZENummer" autocomplete="off"
                          v-model="facilitySettings.bezirkZENummer" :disabled="sending"/>
                <span class="md-error"
                      v-if="!$v.facilitySettings.bezirkZENummer.required">Bezirk ZE-Nummer ist notwendig</span>
              </md-field>
            </div>
            <div class="cell large-9 medium-6 small-12"></div>
          </div>
          <div class="grid-x grid-padding-x">
            <div class="cell large-3 medium-6 small-12">
              <md-field :class="getValidationClass('bezirkPersonenkreis')">
                <label>Bezirk Personenkreis 10 Stunden</label>
                <md-input name="bezirkPersonenkreis" id="bezirkPersonenkreis" autocomplete="off"
                          v-model="facilitySettings.bezirkPersonenkreis" :disabled="sending"/>
                <span class="md-error"
                      v-if="!$v.facilitySettings.bezirkPersonenkreis.required">Bezirk Personenkreis ist notwendig</span>
              </md-field>
            </div>
            <div class="cell large-3 medium-6 small-12">
              <md-field :class="getValidationClass('bezirkSchluessel')">
                <label>Bezirk Schlüssel 10 Stunden</label>
                <md-input name="bezirkSchluessel" id="bezirkSchluessel" autocomplete="off"
                          v-model="facilitySettings.bezirkSchluessel" :disabled="sending"/>
                <span class="md-error"
                      v-if="!$v.facilitySettings.bezirkSchluessel.required">Bezirk Schlüssel ist notwendig</span>
              </md-field>
            </div>
            <div class="cell large-3 medium-6 small-12">
              <md-field :class="getValidationClass('pauschale10Stunden')">
                <label>Pauschale 10 Stunden (in EUR)</label>
                <md-input id="pauschale10Stunden" name="pauschale10Stunden" autocomplete="off" type="number"
                          v-model="facilitySettings.pauschale10Stunden" :disabled="sending"/>
                <span class="md-error" v-if="!($v.facilitySettings.pauschale10Stunden.required)">Pauschale 10 Stunden ist notwendig</span>
              </md-field>
            </div>
          </div>
          <div class="grid-x grid-padding-x">
            <div class="cell large-3 medium-6 small-12">
              <md-field :class="getValidationClass('bezirkPersonenkreis50Stunden')">
                <label>Bezirk Personenkreis 50 Stunden</label>
                <md-input name="bezirkPersonenkreis50Stunden" id="bezirkPersonenkreis50Stunden" autocomplete="off"
                          v-model="facilitySettings.bezirkPersonenkreis50Stunden" :disabled="sending"/>
                <span class="md-error"
                      v-if="!$v.facilitySettings.bezirkPersonenkreis50Stunden.required">Bezirk Personenkreis ist notwendig</span>
              </md-field>
            </div>
            <div class="cell large-3 medium-6 small-12">
              <md-field :class="getValidationClass('bezirkSchluessel50Stunden')">
                <label>Bezirk Schlüssel 50 Stunden</label>
                <md-input name="bezirkSchluessel50Stunden" id="bezirkSchluessel50Stunden" autocomplete="off"
                          v-model="facilitySettings.bezirkSchluessel50Stunden" :disabled="sending"/>
                <span class="md-error"
                      v-if="!$v.facilitySettings.bezirkSchluessel50Stunden.required">Bezirk Schlüssel ist notwendig</span>
              </md-field>
            </div>
            <div class="cell large-3 medium-6 small-12">
              <md-field :class="getValidationClass('pauschale50Stunden')">
                <label>Pauschale 50 Stunden (in EUR)</label>
                <md-input id="pauschale50Stunden" name="pauschale50Stunden" autocomplete="off" type="number"
                          v-model="facilitySettings.pauschale50Stunden" :disabled="sending"/>
                <span class="md-error" v-if="!($v.facilitySettings.pauschale50Stunden.required)">Pauschale 50 Stunden ist notwendig</span>
              </md-field>
            </div>
          </div>
        </div>
        <div class="cell large-6 medium-12 small-12">
        </div>
      </div>
    </md-tab>
  </md-tabs>
</template>

<script>
import Spinner from 'vue-simple-spinner';
import Vue from 'vue';
import Facility from '../../entities/Facility';
import ImportNotPossibleCard from '../cards/ImportNotPossibleCard';

import {validationMixin} from 'vuelidate'
import {
  required,
} from 'vuelidate/lib/validators'

export default {
  name: 'FacilitySettingsForm',
  mixins: [validationMixin],

  components: {
    Facility,
    'vue-simple-spinner': Spinner,
    ImportNotPossibleCard,
  },

  props: {
    facilitySettings: {
      required: true
    },
    printMargins: {
      required: true
    },
    facility: {
      required: true
    },
    sending: {
      required: true
    },
    facilityReferenceNo: {
      required: true
    },
  },

  mounted() {
    this.initData();
  },

  data: () => ({
    invalid: true,
    uploading: false,
    options: {
      url: '',
      maxFilesize: 0.5, // 500 KB
      headers: {
        'accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('sose.access_token')
      },
      createImageThumbnails: false,
      thumbnailWidth: 250,
      thumbnailHeight: 50,
      previewTemplate: '<div class="dz-preview dz-file-preview"></div>',
      dictDefaultMessage: 'EXCEL-Datei auswählen',
      acceptedFiles: '.xls, .xlsx',
    },

    bookingTimeSlots: [],
    bookingTimesSteps: [
      {number: 1, value: '15 Minuten'},
      {number: 2, value: '30 Minuten'},
      {number: 3, value: '45 Minuten'},
      {number: 4, value: '60 Minuten'},
    ],
    coreTimeSlots: [],
  }),

  validations: {
    facilitySettings: {
      bookingTimesStart: {
        required,
      },
      bookingTimesEnd: {
        required,
      },
      timeStepMinutesTimes15: {
        required,
      },
      bezirkZENummer: {
        required,
      },
      bezirkPersonenkreis: {
        required,
      },
      bezirkSchluessel: {
        required,
      },
      pauschale10Stunden: {
        required,
      },
      bezirkPersonenkreis50Stunden: {
        required,
      },
      bezirkSchluessel50Stunden: {
        required,
      },
      pauschale50Stunden: {
        required,
      },
    },
    printMargins: {
      top: {
        required,
      },
      left: {
        required,
      },
      right: {
        required,
      },
      bottom: {
        required,
      },
    },
    facility: {
      coreTimeStart: {
        required,
      },
      coreTimeEnd: {
        required,
      },
    },
  },

  methods: {
    initData() {
      this.initTimeSlots();
    },

    initTimeSlots() {
      if (this.facilitySettings.bookingTimesStart) {
        this.facilitySettings.bookingTimesStart = this.facilitySettings.bookingTimesStart.substr(0, 5);
      } else {
        this.facilitySettings.bookingTimesStart = '';
      }
      if (this.facilitySettings.bookingTimesEnd) {
        this.facilitySettings.bookingTimesEnd = this.facilitySettings.bookingTimesEnd.substr(0, 5);
      } else {
        this.facilitySettings.bookingTimesEnd = '';
      }

      if (this.facility.coreTimeStart) {
        this.facility.coreTimeStart = this.facility.coreTimeStart.substr(0, 5);
      } else {
        this.facility.coreTimeStart = '';
      }
      if (this.facility.coreTimeEnd) {
        this.facility.coreTimeEnd = this.facility.coreTimeEnd.substr(0, 5);
      } else {
        this.facility.coreTimeEnd = '';
      }

      this.bookingTimeSlots = [];

      let start = Vue.moment(Facility.facility.coreTimeStart, 'HH:mm');
      while (Vue.moment(start).isSameOrBefore(Vue.moment(Facility.facility.coreTimeEnd, 'HH:mm'))) {
        this.bookingTimeSlots.push(Vue.moment(start).format('HH:mm'));
        start = Vue.moment(start).add(this.facilitySettings.timeStepMinutesTimes15 * 15, 'minutes');
      }

      this.coreTimeSlots = [];
      start = Vue.moment(this.facilitySettings.bookingTimesStart, 'HH:mm');
      while (Vue.moment(start).isSameOrBefore(Vue.moment(this.facilitySettings.bookingTimesEnd, 'HH:mm'))) {
        this.coreTimeSlots.push(Vue.moment(start).format('HH:mm'));
        start = Vue.moment(start).add(this.facilitySettings.timeStepMinutesTimes15 * 15, 'minutes');
      }
    },

    selectBookingTimesStep() {
      // Reset values
      this.facilitySettings.bookingTimesStart = '';
      this.facilitySettings.bookingTimesEnd = '';

      this.facility.coreTimeStart = '';
      this.facility.coreTimeEnd = '';

      this.initTimeSlots();
    },

    getValidationClass(fieldName) {
      let field = this.$v.facilitySettings[fieldName];
      if (!field) {
        field = this.$v.facility[fieldName];
      }
      if (!field) {
        field = this.$v.printMargins[fieldName];
      }
      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },

    validateFacilitySettings() {
      this.$v.$touch();
      this.invalid = this.$v.$invalid;
    },

    validateBookingStartTime() {
      if (this.facilitySettings) {
        if (Vue.moment(this.facilitySettings.bookingTimesStart, 'HH:mm').isAfter(Vue.moment(this.facilitySettings.bookingTimesEnd, 'HH:mm'))) {
          this.facilitySettings.bookingTimesEnd = Vue.moment(this.facilitySettings.bookingTimesStart, 'HH:mm').format('HH:mm');
        }
        if (Vue.moment(this.facilitySettings.bookingTimesStart, 'HH:mm').isAfter(Vue.moment(this.facility.coreTimeStart, 'HH:mm'))) {
          this.facility.coreTimeStart = Vue.moment(this.facilitySettings.bookingTimesStart, 'HH:mm').format('HH:mm');
        }
        this.initTimeSlots();
      }
    },

    validateBookingEndTime() {
      if (this.facilitySettings) {
        if (Vue.moment(this.facilitySettings.bookingTimesEnd, 'HH:mm').isBefore(Vue.moment(this.facilitySettings.bookingTimesStart, 'HH:mm'))) {
          this.facilitySettings.bookingTimesStart = Vue.moment(this.facilitySettings.bookingTimesEnd, 'HH:mm').format('HH:mm');
        }
        if (Vue.moment(this.facilitySettings.bookingTimesEnd, 'HH:mm').isBefore(Vue.moment(this.facility.coreTimeEnd, 'HH:mm'))) {
          this.facility.coreTimeEnd = Vue.moment(this.facilitySettings.bookingTimesEnd, 'HH:mm').format('HH:mm');
        }
        this.initTimeSlots();
      }
    },

    validateCoreStartTime() {
      if (this.facility) {
        if (Vue.moment(this.facility.coreTimeStart, 'HH:mm').isAfter(Vue.moment(this.facility.coreTimeEnd, 'HH:mm'))) {
          this.facility.coreTimeEnd = Vue.moment(this.facility.coreTimeStart, 'HH:mm').format('HH:mm');
        }
      }
    },

    validateCoreEndTime() {
      if (this.facility) {
        if (Vue.moment(this.facility.coreTimeEnd, 'HH:mm').isBefore(Vue.moment(this.facility.coreTimeStart, 'HH:mm'))) {
          this.facility.coreTimeStart = Vue.moment(this.facility.coreTimeEnd, 'HH:mm').format('HH:mm');
        }
      }
    },
  },

  computed: {
    isAdmin() {
      return this.$store.getters.admin;
    },
  }
};
</script>

<style lang="scss" scoped>
.button {
  margin-bottom: 0;
}

.md-tooltip {
  font-size: medium;
  white-space: normal;
}
</style>

