import Person from "../entities/Person"
import Address from "../entities/Address"
import Contact from "../entities/Contact"
import BankAccount from "../entities/BankAccount"
import KibigData from "../entities/KibigData"

export default {

  name: 'Facility',

  facility: {
    abbreviation: '',
    address: Address.address,
    archivedEmployees: [],
    bankAccount: BankAccount.bankAccount,
    bookingTimeTemplates: [],
    childrenCounter: 0,
    closingTimes: null,
    creditorId: '',
    contact: Contact.contact,
    coreTimeStart: '06:00:00',
    coreTimeEnd: '22:00:00',
    customerNo: '',
    deleted: false,
    employeeCounter: 0,
    facilitySettings: {
      bezirkPersonenkreis: null,
      bezirkSchluessel: null,
      bezirkZENummer: null,
      bookingTimesEnd: null,
      bookingTimesStart: null,
      freeAttributes: [],
      letterPaperFirstId: null,
      letterPaperSecondId: null,
      pauschale10Stunden: null,
      pauschale50Stunden: null,
      providesLunch: true,
      pushMessagesRepeatInterval: 43200,
      standardTemplates: [],
      templates: [],
      timeStepMinutesTimes15: 2,
      printMargins: {
        bottom: 30,
        left: 30,
        right: 30,
        top: 30,
      },
      useLetterPaperFirstForAllPages: false,
    },
    facilityType: '',
    facilityTypeLabel: '',
    feeTemplates: null,
    id: '',
    invoiceNumberTemplate: '',
    kibigData: KibigData.kibigData,
    name: '',
    referenceNumber: '',
    responsiblePerson: Person.person,
    useOrganizationAccount: false,
  }
}

