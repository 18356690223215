<template>
  <div>
    <md-dialog :md-click-outside-to-close="false" v-if="facility" id="editFacilityDialogId" :md-active.sync="showDialog"
               @md-closed="closeDialog">

      <md-dialog-title>
        <form novalidate @submit.prevent="validateFacility">

          <div class="grid-x grid-padding-x">
            <div v-if="mode === 'update'" class="cell large-5 medium-5">
              <i class="fi-pencil"></i>&nbsp;&nbsp;&nbsp;Einrichtungsdaten bearbeiten
              {{facility.referenceNumber}}
            </div>
            <div v-else-if="mode === 'add'" class="cell large-5 medium-5">
              <i class="fi-home"></i>&nbsp;&nbsp;&nbsp;Neue Einrichtung hinzufügen
            </div>
            <div class="cell large-2 medium-2" style="margin-top: -5px;">
              <SessionTime></SessionTime>
            </div>
            <div class="cell large-5 medium-5 text-center">
              <button v-if="mode === 'update'" class="button success" type="submit" :disabled="sending"><i
                  class="fi-save"></i>&nbsp;&nbsp;&nbsp;Änderungen
                speichern
              </button>
              <button v-else-if="mode === 'add'" class="button success" type="submit" :disabled="sending"><i
                  class="fi-home"></i>&nbsp;&nbsp;&nbsp;Einrichtung hinzufügen
              </button>
              <button class="button alert" style="margin-left: 1rem;" @click="closeDialog" type="button"
                      :disabled="sending">
                <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Abbrechen
              </button>
            </div>
          </div>

        </form>

      </md-dialog-title>

      <md-dialog-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        <br>

        <md-tabs id="editFacilityTabsId" md-dynamic-height>
          <md-tab md-label="Stammdaten">
            <div class="grid-x grid-padding-x">
              <div class="cell large-4 medium-4 small-8">
                <md-field :class="getValidationClass('name')">
                  <label for="facility-name">Name der Einrichtung</label>
                  <md-input name="facility-name" id="facility-name" autocomplete="off"
                            v-model="facility.name" :disabled="sending"/>
                  <span class="md-error" v-if="!$v.facility.name.required">Name ist notwendig</span>
                  <span class="md-error" v-else-if="!$v.facility.name.minlength">Ungültiger Name</span>
                </md-field>
              </div>
              <div class="cell large-2 medium-2 small-4">
                <md-field :class="getValidationClass('abbreviation')">
                  <label for="facility-abbreviation">Kürzel</label>
                  <md-input name="facility-abbreviation" id="facility-abbreviation" autocomplete="off"
                            v-model="facility.abbreviation" :disabled="sending"/>
                  <span class="md-error" v-if="!$v.facility.abbreviation.required">Kürzel ist notwendig</span>
                  <span class="md-error" v-else-if="!$v.facility.abbreviation.minlength">Ungültiges Kürzel</span>
                </md-field>
              </div>
              <div class="cell large-6 medium-6 small-12">
                <md-field :class="getValidationClass('facilityType')">
                  <label>Einrichtungstyp</label>
                  <md-select id="facility-type" v-model="facility.facilityType"
                             autocomplete="off"
                             :disabled="sending">
                    <md-option v-for="type of labels.facilityTypes" v-bind:value="type" :key="type">
                      {{labels.facilityType[type]}}
                    </md-option>
                  </md-select>
                  <span class="md-error"
                        v-if="!$v.facility.facilityType.required">Einrichtungstyp ist notwendig</span>
                </md-field>
              </div>
              <div class="cell">
                <AddressForm ref="facilityAddressForm" :address="facility.address" :sending="sending"></AddressForm>
              </div>
              <div class="cell large-6 medium-12 small-12">
                <md-field>
                  <label for="creditor-id">Gläubiger-Identifikationsnummer</label>
                  <md-input name="creditor-id" id="creditor-id" autocomplete="off" maxlength="35"
                            v-model="facility.creditorId" :disabled="sending"/>
                </md-field>
              </div>
            </div>
          </md-tab>
          <md-tab v-if="facility.contact" md-label="Kontakt">
            <ContactForm ref="facilityContactForm" :contact="facility.contact"
                         :sending="sending"></ContactForm>
          </md-tab>
          <md-tab md-label="Leitung">
            <PersonForm ref="responsiblePersonForm" :person="facility.responsiblePerson"
                        :sending="sending" :birthDateDisabled="false"></PersonForm>
            <AddressForm v-if="facility.responsiblePerson" ref="responsiblePersonAddressForm"
                         :address="facility.responsiblePerson.address" :sending="sending"></AddressForm>
          </md-tab>
          <md-tab md-label="Bankverbindung">

            <div>
              <md-radio v-model="facility.useOrganizationAccount" :value="false" class="md-primary">Eigenes Konto</md-radio>
              <md-radio v-model="facility.useOrganizationAccount" :value="true" class="md-primary">Konto des Trägers</md-radio>
            </div>
            <br>
            <BankAccountForm v-if="facility.useOrganizationAccount" :bankAccount="organization.bankAccount"
                             :paymentMethod="''" :mandateReferenceDisabled="true"
                             :sending="true"></BankAccountForm>
            <BankAccountForm v-else ref="bankAccountForm" :bankAccount="facility.bankAccount"
                             :paymentMethod="''" :mandateReferenceDisabled="true"
                             :sending="sending"></BankAccountForm>
          </md-tab>
          <md-tab md-label="KiBiG Daten">
            <KibigDataForm ref="kibigDataForm" :kibigData="facility.kibigData" :sending="sending"
                           :facilityReferenceNo="facility.referenceNumber"></KibigDataForm>
          </md-tab>
          <md-tab md-label="Weitere Einstellungen">
            <FacilitySettingsForm ref="facilitySettingsForm" :facilitySettings="facility.facilitySettings"
                                  :sending="sending" :facility="facility" :printMargins="facility.facilitySettings.printMargins"
                                  :facilityReferenceNo="facility.referenceNumber"></FacilitySettingsForm>
          </md-tab>
        </md-tabs>

      </md-dialog-content>

      <button class="close-button" type="button" @click="closeDialog">
        <span aria-hidden="true">&times;</span>
      </button>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="confirmMissingDataDialog">
      <div class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title><i class="fi-home"></i>&nbsp;&nbsp;&nbsp;Unvollständigen
          Datensatz für Einrichtung bestätigen
        </md-dialog-title>
        <div class="cell">
          <md-content>Soll der unvollständige Datensatz wirklich gespeichert werden?
          </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <div @click="doConfirmation" class="button success"><i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Ja, speichern</div>
          <button class="button alert" style="margin-left: 1rem;"
                  @click="closeConfirmationDialog"
                  type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="closeConfirmationDialog">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

  </div>

</template>

<script>
  import jQuery from 'jquery';
  import PersonForm from '../forms/PersonForm';
  import ContactForm from '../forms/ContactForm';
  import AddressForm from '../forms/AddressForm';
  import BankAccountForm from '../forms/BankAccountForm';
  import BankAccountView from '../views/BankAccountView';
  import KibigDataForm from '../forms/KibigDataForm';
  import FacilitySettingsForm from '../forms/FacilitySettingsForm';
  import Facility from '../../entities/Facility';
  import Person from '../../entities/Person';

  import FacilityService from '../../services/FacilityService';
  import OrganizationService from "../../services/OrganizationService";
  import HttpErrorHandler from '../../services/HttpErrorHandler';
  import SessionTime from '../animations/SessionTime';

  import {validationMixin} from 'vuelidate';

  import {
    required,
    minLength,
  } from 'vuelidate/lib/validators'

  export default {
    name: 'EditFacilityDialog',
    mixins: [validationMixin],

    components: {
      PersonForm,
      ContactForm,
      AddressForm,
      Facility,
      BankAccountForm,
      BankAccountView,
      KibigDataForm,
      FacilitySettingsForm,
      SessionTime,
    },

    props: {
      facility: {
        required: true
      },
      showDialog: {
        required: true
      },
      mode: {
        required: true
      }
    },

    watch: {
      facility() {
        if (this.facility && this.facility.coreTimeStart) {
          this.coreTimeStart = this.facility.coreTimeStart;
        }
        if (this.facility && this.facility.coreTimeEnd) {
          this.coreTimeEnd = this.facility.coreTimeEnd;
        }

        if (this.facility && this.showDialog) {
          setTimeout(() => jQuery('.md-dialog-container').attr('style','width: 80%;'), 250);
        }

        if (this.facility) {
          if (!this.facility.responsiblePerson) {
            this.facility.responsiblePerson = Person.person;
          }
        }

        HttpErrorHandler.maintainDarkMode(this, 100);
      },
    },

    data: () => ({
      success: true,
      sending: false,
      confirmMissingDataDialog: false,
      dateTimePhrases: {ok: 'Übernehmen', cancel: 'Abbrechen'},
      minuteStep: 30,
      minDatetime: '06:00:00',
      maxDatetime: '21:00:00',
      coreTimeStart: Facility.facility.coreTimeStart,
      coreTimeEnd: Facility.facility.coreTimeEnd,
    }),

    validations: {
      facility: {
        abbreviation: {
        },
        name: {
          required,
          minLength: minLength(3)
        },
        facilityType: {
          required,
        },
      }
    },

    methods: {

      selectedNthTab(no) {
        setTimeout(() => {

          setTimeout(() => {
            jQuery('#editFacilityTabsId .md-tabs-navigation button:nth-child(' + no + ')').trigger('click');
          }, 50);

          if (this.routingHistory.pop().includes('/Verwaltung/Datenexport')) {
            setTimeout(() => {
              jQuery('#editKibigDataTabsId .md-tabs-navigation button:nth-child(2)').trigger('click');
            }, 150);
          }

        }, 250)
      },

      getValidationClass(fieldName) {
        let field = this.$v.facility[fieldName];
        if (!field) {
          field = this.$v.facility.contact[fieldName];
        }
        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },

      updateFacility() {
        this.sending = true;
        this.success = false;
        FacilityService.updateFacility(this.facility)
          .then(response => {
            this.form = response.data;
            this.success = true;
            this.sending = false;
            this.$emit("updateFacilitySuccess");
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Speichern der Änderungen für Einrichtung ' + this.facility.name);
            this.sending = false;
          })
      },

      addFacility() {
        this.sending = true;
        this.success = false;
        OrganizationService.addFacility(this.facility)
          .then(response => {
            this.form = response.data;
            this.success = true;
            this.sending = false;
            this.$emit("updateFacilitySuccess");
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Hinzufügen der Einrichtung ' + this.facility.name);
            this.sending = false;
          })
      },

      validateFacility() {
        this.$v.$touch();

        this.$refs.facilityAddressForm.validateAddress();
        if (this.$v.$invalid || this.$refs.facilityAddressForm.invalid) {
          jQuery('.md-tabs-navigation').children(':button.md-button').first().trigger('click');
          return;
        }

        this.$refs.facilityContactForm.validateContact();
        if (this.$refs.facilityContactForm.invalid) {
          jQuery('.md-tabs-navigation').children(':button.md-button').first().next().trigger('click');
          // return;
        }

        this.$refs.responsiblePersonForm.validatePerson();
        this.$refs.responsiblePersonAddressForm.validateAddress();
        if (this.$refs.responsiblePersonForm.invalid || this.$refs.responsiblePersonAddressForm.invalid) {
          jQuery('.md-tabs-navigation').children(':button.md-button').first().next().next().trigger('click');
          // return;
        }

        if (!this.facility.useOrganizationAccount && this.$refs.bankAccountForm) {
          this.$refs.bankAccountForm.validateBankAccount();
          if (this.$refs.bankAccountForm.invalid) {
            jQuery('.md-tabs-navigation').children(':button.md-button').first().next().next().next().trigger('click');
            // return;
          }
        }

        this.$refs.kibigDataForm.validateKibigData();
        if (this.$refs.kibigDataForm.invalid) {
          jQuery('.md-tabs-navigation').children(':button.md-button').first().next().next().next().next().trigger('click');
          // return;
        }

        this.$refs.facilitySettingsForm.validateFacilitySettings();
        if (this.$refs.facilitySettingsForm.invalid) {
          jQuery('.md-tabs-navigation').children(':button.md-button').first().next().next().next().next().next().trigger('click');
          // return;
        }

        if (!(this.$v.$invalid ||
          this.$refs.facilityAddressForm.invalid ||
          this.$refs.facilityContactForm.invalid ||
          this.$refs.responsiblePersonForm.invalid ||
          this.$refs.responsiblePersonAddressForm.invalid ||
          (!this.facility.useOrganizationAccount && this.$refs.bankAccountForm && this.$refs.bankAccountForm.invalid) ||
          this.$refs.kibigDataForm.invalid ||
          this.$refs.facilitySettingsForm.invalid)) {

          if (this.mode === 'update') {
            this.updateFacility();
          }
          if (this.mode === 'add') {
            this.addFacility();
          }
        } else {
          setTimeout(() => this.confirmMissingData(), 250);
        }
      },

      closeDialog() {
        this.$v.$reset();
        this.$emit("closeEditFacilityDialog");
      },

      doConfirmation() {
        this.confirmMissingDataDialog = false;
        jQuery('#editFacilityDialogId').attr("style", "display:normal");
        if (this.mode === 'update') {
          this.updateFacility();
        }
        if (this.mode === 'add') {
          this.addFacility();
        }
      },

      confirmMissingData() {
        jQuery('#editFacilityDialogId').attr("style", "display:none");
        this.confirmMissingDataDialog = true;
      },

      closeConfirmationDialog() {
        this.confirmMissingDataDialog = false;
        jQuery('#editFacilityDialogId').attr("style", "display:normal");
      },
    },

    computed: {

      organization() {
        return this.$store.getters.organization;
      },

      isAuthenticated() {
        return this.$store.getters.authenticated
      },

      isAdmin() {
        return this.$store.getters.admin
      },

      isSage() {
        return this.$store.getters.sage
      },

      user() {
        return this.$store.getters.user
      },

      labels() {
        return this.$store.getters.labels
      },

      routingHistory() {
        return this.$store.getters.routingHistory
      },
    },
  };
</script>

<style scoped>
  .md-dialog /deep/.md-dialog-container {
    //min-width: 95%;
    //min-height: 95%;
  }

  .md-tab {
    padding-left: 0;
    padding-right: 0;
  }
</style>

<style>
  .vdatetime-overlay {
    background: rgba(0, 0, 0, 0);
  }

  .vdatetime-popup__header {
    font-size: 1rem;
    padding: 18px 20px;
  }

  .vdatetime-time-picker__list {
    /*height: 200px;*/
  }

  .vdatetime-time-picker__list--hours {
    /*height: 200px;*/
  }

  .vdatetime-popup__actions__button {
    background-color: #e1faea;
    color: black !important;
    border-radius: 3px;
    font-size: 0.9rem;
  }

  .vdatetime-popup__actions__button--cancel {
    background-color: #e07373;
    margin-right: 12px;
  }

  .vdatetime-popup__actions__button--cancel:hover {
    background-color: #af2510 !important;
  }

  .vdatetime-popup__actions {
    padding: 10px 20px 10px 30px;
    text-align: center;
  }

  .theme-green .vdatetime-popup__actions__button:hover, .vdatetime-popup__actions__button:focus {
    background-color: #588668;
    color: white !important;
    border-radius: 3px;
  }

  .theme-green .vdatetime-popup__header,
  .theme-green .vdatetime-calendar__month__day--selected > span > span,
  .theme-green .vdatetime-calendar__month__day--selected:hover > span > span {
    background: #2e8b57;
  }

  .theme-green .vdatetime-year-picker__item--selected,
  .theme-green .vdatetime-time-picker__item--selected,
  .theme-green .vdatetime-popup__actions__button {
    color: #2e8b57;
  }
</style>
