<template>
  <div class="cell text-center">
    <md-card md-with-hover class="text-left">
      <md-card-header>
        <div class="md-layout">
          <div class="md-layout-item md-size-15">
            <img width="55px" src="../../assets/icons/bulp.png"/>
          </div>
          <div class="md-layout-item">
            <div class="md-title">Daten-Import noch nicht möglich</div>
            <div v-if="alternativeText" class="md-subhead" v-html="alternativeText"></div>
            <div v-else class="md-subhead"><span>{{functionTitle}}</span>&nbsp;können&nbsp;nur&nbsp;mit&nbsp;Einrichtungen&nbsp;verwaltet&nbsp;werden.
            </div>
          </div>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-15">
          </div>
          <div class="md-layout-item">
            Sie möchten gerne einen Daten-Import durchführen?
            Vorraussetzung hierfür ist, dass die Einrichtung für welche Sie den Import durchführen möchten bereits im System angelegt wurde.<br><br>
            <b>Was bedeutet das konkret?</b><br>

            Sie sind gerade dabei eine neue Einrichtung anzulegen.
            Speichern Sie einfach jetzt Ihre bereits eingegebenen Daten mit
            <span class="button secondary" style="padding: 3px;">Einrichtung hinzufügen
              <md-tooltip>Zum Ausführen der Funktion verwenden Sie bitte den Button oben rechts.
              &nbsp;
              <button class="button success"><i class="fi-home"></i>&nbsp;&nbsp;&nbsp;Einrichtung hinzufügen</button>
              &nbsp;</md-tooltip>
            </span> .<br><br>

            Wählen Sie danach die neu angelegte Einrichtung in der Liste aus und wählen Sie
            <span class="button secondary" style="padding: 3px;">Datenimport durchführen
              <md-tooltip>Zum Ausführen der Funktion verwenden Sie bitte nach der Auswahl den Button.
              &nbsp;
              <md-button class="md-icon-button md-raised md-forth"><md-icon>cloud_upload</md-icon></md-button>
              &nbsp;</md-tooltip>
            </span> .
            Der Import kann dann durchgeführt werden.
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
  export default {
    name: 'ImportNotPossibleCard',
    props: {
      functionTitle: {
        required: false
      },
      alternativeText: {
        required: false
      },
    }
  }
</script>

<style lang="scss" scoped>
  span {
    color: cornflowerblue;
    font-weight: bold;
  }

  .md-card {
    max-width: 800px;
    min-width: 200px;
    margin: 4px;
    display: inline-block;
    vertical-align: top;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    max-width: 330px;
    white-space: pre-wrap;
  }

  .button {
    margin-bottom: 5px;
  }
</style>
