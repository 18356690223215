<template>
  <div v-if="isAuthenticated">

    <div class="grid-x grid-padding-x">
      <div class="cell callout success"><h3><i class="fi-home"></i>&nbsp;&nbsp;&nbsp;Einrichtungen
      </h3></div>
    </div>

    <div class="grid-x grid-padding-x grid-padding-y">

      <div class="cell">
        <md-table v-model="searched" md-sort="name" md-sort-order="asc" md-card md-fixed-header
                  @md-selected="onSelect" :style="tableStyle">
          <md-table-toolbar>
            <div class="md-toolbar-section-start grid-x" style="padding-top: 0.5rem;">
              <div class="cell large-3 medium-5 small-12">
                <p class="md-title ai-number">{{searched.length}}&nbsp;Einrichtungen</p>
              </div>
              <div class="cell large-1 medium-1 hide-for-small-only">
                <vue-simple-spinner v-if="sending"></vue-simple-spinner>
              </div>

              <div v-if="isSage" class="cell large-8 medium-6 small-12">
                <md-autocomplete id="organization-name-id" v-model="selectedOrganizationName" :md-options="availableOrganizationNames"
                                 @md-changed="setOrganizations" @md-opened="setOrganizations"
                                 @md-selected="selectOrganization">
                  <label>Träger</label>

                  <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                    <md-highlight-text :md-term="term">{{ item.name }}</md-highlight-text>
                  </template>

                  <template slot="md-autocomplete-empty" slot-scope="{ term }">
                    "{{ term }}" nicht gefunden
                  </template>

                </md-autocomplete>

              </div>
              <div v-if="isSage" class="cell large-4 medium-6"></div>

              <div class="cell large-8 medium-6 small-12">
                <md-field md-clearable class="md-toolbar-section-end">
                  <md-input placeholder="Suche Name der Einrichtung ..." v-model="search" @input="searchOnTable"/>
                </md-field>
              </div>

              <div class="cell" style="padding-bottom: 15px;">
                <div class="grid-x">
                  <div class="cell medium-6 large-4 hide-for-small-only">
                  </div>
                  <div v-if="canWrite" class="cell medium-6 large-8">
                    <md-button v-if="!isSage && (isAdmin || isManagement)" @click="addFacilityDialog"
                               class="md-icon-button md-raised md-primary">
                      <md-icon>add</md-icon>
                      <md-tooltip>Neue Einrichtung hinzufügen</md-tooltip>
                    </md-button>
                    <md-button v-if="isTreasurer || isAdmin || isManagement" @click="editFacilityDialog"
                               :disabled="!selected || sending"
                               class="md-icon-button md-raised md-third">
                      <md-icon>edit</md-icon>
                      <md-tooltip>Einrichtung bearbeiten</md-tooltip>
                    </md-button>
                    <md-button v-if="isAdmin" @click="deleteFacilityDialog"
                               :disabled="!selected || sending"
                               class="md-icon-button md-raised md-accent">
                      <md-icon>delete</md-icon>
                      <md-tooltip>Einrichtung löschen</md-tooltip>
                    </md-button>
                    <md-button v-if="!isSage && (isTreasurer || isAdmin || isFacilityAdmin || isManagement)" @click="onEditFacilityTemplates"
                               :disabled="!selected || sending"
                               class="md-icon-button md-raised md-fifth">
                      <md-icon>print</md-icon>
                      <md-tooltip>Druckvorlagen bearbeiten</md-tooltip>
                    </md-button>
                    <md-button v-if="!isSage && (isAdmin || isFacilityAdmin)" @click="onImportFacilityData"
                               :disabled="!selected || sending"
                               class="md-icon-button md-raised md-forth">
                      <md-icon>cloud_upload</md-icon>
                      <md-tooltip>Datenimport durchführen</md-tooltip>
                    </md-button>
                    <md-button v-if="isSage" @click="onSendPushTestMessage"
                               :disabled="!selected || sending"
                               class="md-icon-button md-raised md-forth">
                      <md-icon>message</md-icon>
                      <md-tooltip>Testnachricht senden</md-tooltip>
                    </md-button>
                  </div>
                </div>
              </div>

            </div>
          </md-table-toolbar>

          <md-table-empty-state v-if="search"
                                md-label="Keine Einrichtung gefunden"
                                :md-description="`Keine Einrichtung mit dem Suchbegriff '${search}' gefunden. Versuchen Sie es mit einem anderen Begriff oder legen Sie neue Einrichtung an.`">
          </md-table-empty-state>

          <md-table-row :id="item.referenceNumber" slot="md-table-row" slot-scope="{ item }" :class="getClass(item)"
                        md-selectable="single">
            <md-table-cell v-if="isSage" md-label="Referenznummer" md-sort-by="referenceNumber" md-numeric>{{
              item.referenceNumber }}
            </md-table-cell>
            <md-table-cell md-label="Name" md-sort-by="name">{{ item.name }}</md-table-cell>
            <md-table-cell md-label="Kürzel" md-sort-by="abbreviation">{{ item.abbreviation }}</md-table-cell>
            <md-table-cell md-label="Adresse" md-sort-by="address.street">
              <AddressView :address="item.address" :raw="true"/>
            </md-table-cell>
            <md-table-cell md-label="Kontakt" md-sort-by="contact.email">
              <ContactView :contact="item.contact" :raw="true"/>
            </md-table-cell>
            <md-table-cell md-label="Leitung" md-sort-by="responsiblePerson.lastname">
              <PersonView :facilityRef="item.referenceNumber" :person="item.responsiblePerson" :birthDateDisabled="false" :raw="true"/>
            </md-table-cell>
            <md-table-cell md-label="Einrichtungstyp" md-sort-by="facilityTypeLabel">
              {{item.facilityTypeLabel}}
            </md-table-cell>
            <md-table-cell md-label="Kinder" md-sort-by="childrenCounter">
              {{item.childrenCounter}}
            </md-table-cell>
            <md-table-cell md-label="Mitarbeiter" md-sort-by="employeeCounter">
              {{item.employeeCounter}}
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </div>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteFacilityDialog">
      <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title><i class="fi-torso-female"></i>&nbsp;&nbsp;&nbsp;Einrichtung löschen - Referenznummer
          {{selected.referenceNumber}}
        </md-dialog-title>
        <div class="cell">
          <md-content class="text-center">Soll die Einrichtung <b>{{selected.name}}</b> wirklich gelöscht werden?</md-content>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <md-checkbox v-model="absolutelySure" :disabled="sending">Ich bin mir absolut sicher!</md-checkbox>
        </div>
        <div class="cell text-center">
          <button v-if="isAdmin" @click="onDeleteFacility" class="button alert" :disabled="sending || !absolutelySure">
            <i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja, Einrichtung jetzt löschen
          </button>
          <button class="button success" style="margin-left: 1rem;"
                  @click="showDeleteFacilityDialog = false"
                  type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="showDeleteFacilityDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <EditFacilityDialog :showDialog="showEditFacilityDialog" :facility="selectedFacility" :mode="dialogMode"
                        ref="editFacilityDialogRef"
                        @updateFacilitySuccess="updateFacilitySuccess"
                        @closeEditFacilityDialog="showEditFacilityDialog = false"></EditFacilityDialog>
  </div>

</template>

<script>
  import jQuery from 'jquery';
  import Spinner from 'vue-simple-spinner';

  import OrganizationService from '../services/OrganizationService';
  import FacilityService from '../services/FacilityService';
  import HttpErrorHandler from '../services/HttpErrorHandler';

  import AddressView from "../components/views/AddressView";
  import ContactView from "../components/views/ContactView";
  import PersonView from "../components/views/PersonView";

  import EditFacilityDialog from "../components/dialogs/EditFacilityDialog";

  import Facility from "../entities/Facility";
  import SageService from "../services/SageService";

  const toLower = text => {
    return text.toString().toLowerCase();
  };

  const searchByName = (items, term) => {
    if (term) {
      return items.filter(item => toLower(item.name).includes(toLower(term)));
    }

    return items;
  };

  const searchByCustomerNo = (items, term) => {
    if (term) {
      return items.filter(item => toLower(item.customerNo).includes(toLower(term)));
    }

    return items;
  };

  export default {
    name: 'Facilities',
    components: {
      EditFacilityDialog,
      AddressView,
      ContactView,
      PersonView,
      Facility,
      'vue-simple-spinner': Spinner
    },

    created() {
      this.initSyncHandler(true);
    },

    beforeDestroy() {
      this.initSyncHandler(false)
    },

    mounted() {
      this.facilityRefNo = this.$route.params['facilityRefNo'];
      this.reloadFacilities();
    },

    data() {
      return {
        sending: false,
        facilities: [],
        searched: [],
        search: null,
        selected: null,
        showDeleteFacilityDialog: false,
        showEditFacilityDialog: false,
        success: false,
        lastFacility: '',
        showCreateFacilityDialog: false,
        showAddFacilityDialog: false,
        dialogMode: 'update',
        selectedFacility: null,
        facilityRefNo: null,
        absolutelySure: false,

        selectedCustomerNo: '',
        selectedOrganizationName: '',
        availableOrganizations: [],
        availableOrganizationNames: [],
      }
    },

    methods: {

      initSyncHandler(init) {
        if (init) {
          setTimeout(() => {
            jQuery('#organization-name-id').children('.md-menu').children('input').bind('focus', () => {
              setTimeout(() => jQuery('#organization-name-id').children('.md-menu').children('input').trigger('click'), 250);
            });
            jQuery('.md-table-content.md-scrollbar.md-theme-default').removeAttr('style');
          }, 500);
        } else {
          jQuery('#organization-name-id').children('input').unbind('focus');
        }
      },

      reloadFacilities() {

        let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
        const reloadIntervalId = setInterval(() => {
          if (this.dataAvailable) {
            if (this.isSage) {
              this.getOrganizations();
              this.getAllFacilities();
            } else {
              this.getAllOrganizationFacilities();
              this.reloadOrganizationInfo();
            }
            clearInterval(reloadIntervalId);

          } else {
            this.sending = true;
            maxTries--;
            if (maxTries <= 0) {
              this.sending = false;
              clearInterval(reloadIntervalId);
              HttpErrorHandler.handleError(null, this, 'Fehler beim Laden von Einrichtung / Organisation');
            }
          }
        }, 250);
      },

      getAllFacilities() {
        this.sending = true;
        FacilityService.getAllFacilities()
          .then(response => {
            this.facilities = response.data;
            this.searchOnTable();
            this.sending = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden aller Einrichtungen');
            this.sending = false;
          })
      },

      getOrganizations() {
        this.sending = true;
        OrganizationService.getAllOrganizations()
          .then(response => {
            this.sending = false;
            this.organizations = response.data;

            this.availableOrganizations = [];
            for (let organization of this.organizations) {
              let organizationFullName = organization.customerNo + ' - ' + organization.name + ' (' + this.labels.organizationType[organization.organizationType] +')';
              this.availableOrganizations.push({customerNo: organization.customerNo, name: organizationFullName});
            }
          })
          .catch(e => {
            this.sending = false;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Liste aller Träger');
          })
      },

      getAllOrganizationFacilities() {
        this.sending = true;
        OrganizationService.getAllFacilities()
          .then(response => {
            this.facilities = response.data;
            this.searchOnTable();
            this.sending = false;

            this.openEditDialog(this.facilityRefNo);

          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Einrichtungen für Träger ' + this.organization.name);
            this.sending = false;
          })
      },

      reloadOrganizationInfo() {
        OrganizationService.getOrganizationInfo()
          .then(response => {
            this.$store.commit('organizationInfo', response.data);
            this.$store.commit('availableFacilities');
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Trägerdaten');
          });
      },

      openEditDialog(facilityRefNo) {
        if (facilityRefNo) {
          setTimeout(() => {
              jQuery('#' + this.facilityRefNo).trigger('click');
              this.facilityRefNo = null;

              for (let facility of this.facilities) {
                if (facility.referenceNumber === facilityRefNo) {
                  this.onSelect(facility);
                  this.editFacilityDialog();

                  if (this.$refs.editFacilityDialogRef) {
                    this.$refs.editFacilityDialogRef.selectedNthTab(5);
                  }
                }
              }
            }, 250
          );
        }
      },

      addFacilityDialog() {
        this.success = false;
        this.dialogMode = 'add';
        this.selectedFacility = JSON.parse(JSON.stringify(Facility.facility));
        if (this.organization.address) {
          this.selectedFacility.address = JSON.parse(JSON.stringify(this.organization.address));
        }
        this.showEditFacilityDialog = true;
      },

      deleteFacilityDialog() {
        this.success = false;
        if (this.selected) {
          this.absolutelySure = false;
          this.lastFacility = this.selected.referenceNumber;
          this.showDeleteFacilityDialog = true;
        }
      },

      onSendPushTestMessage() {
        if (this.selected) {
          this.success = false;
          this.sending = true;
          SageService.pushMessageTest(this.selected.referenceNumber)
            .then(() => {
              this.sending = false;
              this.$store.commit('successMsg', 'An die Einrichtung&nbsp;<b>' + this.selected.name +
                '</b>&nbsp;wurde erfolgreich eine Testnachricht gesendet!');
              this.success = true;
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Versenden einer Testnachricht an Einrichtung ' + this.selected.name);
              this.sending = false;
            })
        }
      },

      onImportFacilityData() {
        if (this.selectedFacility) {
          this.$store.commit('dataImportFacility', this.selectedFacility);
          this.$router.push('/Verwaltung/Einrichtung/Import');
        }
      },

      onEditFacilityTemplates() {
        this.$router.push('/Verwaltung/Einrichtung/Vorlagen');
      },

      editFacilityDialog() {
        this.success = false;
        if (this.selected) {
          this.dialogMode = 'update';
          this.selectedFacility = JSON.parse(JSON.stringify(this.selected));

          if (this.selectedFacility.address === null) {
            this.selectedFacility.address = Facility.facility.address;
          }
          if (this.selectedFacility.bankAccount === null) {
            this.selectedFacility.bankAccount = Facility.facility.bankAccount;
          }
          if (this.selectedFacility.contact === null) {
            this.selectedFacility.contact = Facility.facility.contact;
          }
          if (this.selectedFacility.responsiblePerson === null) {
            this.selectedFacility.responsiblePerson = Facility.facility.responsiblePerson;
          }
          this.showEditFacilityDialog = true;
        }
      },

      updateFacilitySuccess() {
        this.showEditFacilityDialog = false;
        this.lastFacility = this.selectedFacility.name;

        if (this.dialogMode === 'update') {
          this.$store.commit('successMsg', 'Die Änderungen für Einrichtung&nbsp;<b>' + this.lastFacility + '</b>&nbsp;wurden erfolgreich gespeichert!');
          this.success = true;
        }
        if (this.dialogMode === 'add') {
          this.$store.commit('successMsg', 'Die Einrichtung&nbsp;<b>' + this.lastFacility + '</b>&nbsp;wurde erfolgreich hinzugefügt.');
          this.success = true;
        }

        this.success = true;
        this.selected = null;
        this.reloadFacilities();
      },

      onDeleteFacility() {
        if (this.selected) {
          this.success = false;
          this.sending = true;
          FacilityService.deleteFacility(this.selected.referenceNumber)
            .then(() => {
              this.sending = false;
              this.$store.commit('successMsg', 'Die Einrichtung&nbsp;<b>' + this.lastFacility + '</b>&nbsp;wurde erfolgreich gelöscht.');
              this.success = true;
              this.search = null;
              this.selected = null;
              this.showDeleteFacilityDialog = false;
              this.reloadFacilities();
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen von Einrichtung ' + this.selected.name);
              this.sending = false;
            })
        }
      },

      searchOnTable() {
        if (this.labels && this.labels.facilityType) {
          for (let facility of this.facilities) {
            facility.facilityTypeLabel = this.labels.facilityType[facility.facilityType];
          }
          this.searched = searchByCustomerNo(this.facilities, this.selectedCustomerNo);
          this.searched = searchByName(this.searched, this.search);
        }
      },

      getClass: ({id}) => ({
        'md-primary': id === id
      }),

      resetStates() {
        this.success = false;
      },

      onSelect(item) {
        if (item) {
          this.selected = JSON.parse(JSON.stringify(item));
          this.selectedFacility = JSON.parse(JSON.stringify(this.selected));
        } else {
          this.selected = null;
          this.selectedFacility = null;
        }
      },

      setOrganizations(searchTerm) {
        this.availableOrganizationNames = new Promise(resolve => {
          window.setTimeout(() => {
            if (!searchTerm) {
              this.selectedCustomerNo = '';
              this.selectedOrganizationName = '';
              this.searchOnTable();
              resolve(this.availableOrganizations)
            } else {
              const term = searchTerm.toLowerCase();
              resolve(this.availableOrganizations.filter(({name}) => name.toLowerCase().includes(term)))
            }
          }, 5)
        })

      },

      selectOrganization(organization) {
        this.selectedCustomerNo = organization.customerNo;
        this.selectedOrganizationName = organization.name;
        this.searchOnTable();
      },
    },

    computed: {

      tableStyle() {
        return this.$store.getters.sage ? 'min-height: 720px; height: calc(100vh - 345px); overflow-x: hidden' : 'min-height: 620px; height: calc(100vh - 349px); overflow-x: hidden'
      },

      dataAvailable() {
        return this.isAuthenticated && this.organization;
      },

      isAuthenticated() {
        return this.$store.getters.authenticated;
      },

      isTreasurer() {
        return this.$store.getters.treasurer;
      },

      isAdmin() {
        return this.$store.getters.admin;
      },

      isFacility() {
        return this.$store.getters.facility;
      },

      isFacilityAdmin() {
        return this.$store.getters.facilityAdmin;
      },

      isManagement() {
        return this.$store.getters.management;
      },

      isSage() {
        return this.$store.getters.sage;
      },

      canWrite() {
        return this.$store.getters.canWrite;
      },

      organization() {
        return this.$store.getters.organization;
      },

      labels() {
        return this.$store.getters.labels
      },
    }
  }
</script>

<style lang="scss" scoped>
  .md-dialog /deep/.md-dialog-container {
    max-width: 800px;
  }

  i {
    color: black;
  }

  .md-table.md-theme-default .md-table-row.md-selected-single.md-primary {
    background: darkseagreen;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    white-space: normal;
  }
</style>
