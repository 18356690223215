<template>
  <md-tabs id="editKibigDataTabsId" md-dynamic-height>
    <md-tab md-label="Merkmale">
      <div v-if="kibigData" class="grid-x grid-padding-x">
        <div class="cell large-3 medium-6 small-12">
          <md-checkbox class="md-primary" v-model="kibigData.integrativeEinrichtung">integrative Einrichtung</md-checkbox><br>
          <md-checkbox class="md-primary" v-model="kibigData.einzelintegration">Einzelintegration</md-checkbox><br>
          <md-checkbox class="md-primary" v-model="kibigData.schwerpunktKita">Schwerpunkt-Kita</md-checkbox><br>
          <md-checkbox class="md-primary" v-model="kibigData.kleineLandgemeinde">kleine Landgemeinde</md-checkbox><br>
          <md-checkbox class="md-primary" v-model="kibigData.useAssumptionDay">Mariä Himmelfahrt</md-checkbox><br>
          <md-checkbox class="md-primary" v-model="kibigData.usePeaceFestival">Friedensfest</md-checkbox><br>
        </div>
        <div class="cell large-3 medium-6 small-12">
          <md-checkbox class="md-primary" v-model="kibigData.bilingual">zweisprachig</md-checkbox><br>

          <br>
          <div style="display: none">{{selectedNativeLanguage = labels.language[this.kibigData.bilingualLanguage]}}</div>
          <md-autocomplete id="native-language-id" v-model="selectedNativeLanguage" :md-options="languages"
                           @md-changed="getLanguages" @md-opened="getLanguages"
                           @md-selected="selectLanguage" :disabled="!kibigData.bilingual">
            <label>Zweite Sprache</label>

            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
              <md-highlight-text :md-term="term">{{ item.name }}</md-highlight-text>
            </template>

            <template slot="md-autocomplete-empty" slot-scope="{ term }">
              "{{ term }}" nicht gefunden
            </template>

          </md-autocomplete>
        </div>
        <div class="cell large-6 medium-6 small-12">
          <md-checkbox class="md-primary" v-model="kibigData.oeffnungMind45Stunden">Min. 45 Wochenstunden geöffnet</md-checkbox><br>
          <br>
          <h5 class="md-label">Öffnungstage
            <md-tooltip>Klicke auf einzelne Tage, um zwischen Zustand <b>geöffnet</b> und <b>geschlossen</b> zu wechseln.</md-tooltip>
          </h5>
          <md-button v-for="day of openingDays"  :class="getDayClass(day)" @click="toggleOpenDay(day)" :key="day">
            <md-icon><h5 class="md-icon-label">{{day}}</h5></md-icon>
            <md-tooltip>{{getOpenState(day)}}</md-tooltip>
          </md-button>
        </div>
      </div>
    </md-tab>
    <md-tab md-label="Einstellungen">
      <div v-if="kibigData" class="grid-x grid-padding-x">
        <div class="cell large-3 medium-6 small-12">
          <md-field :class="getValidationClass('kibigFacilityNumber')">
            <label for="kibigFacilityNumber">KiBiG Einrichtungsnummer</label>
            <md-input name="kibigFacilityNumber" id="kibigFacilityNumber" autocomplete="off"
                      v-model="kibigData.kibigFacilityNumber" :disabled="sending"/>
            <span class="md-error"
                  v-if="!$v.kibigData.kibigFacilityNumber.required">KiBiG Einrichtungsnummer ist notwendig</span>
          </md-field>
        </div>
        <div class="cell large-3 medium-6 small-12">
          <md-field :class="getValidationClass('kibigXmlImportKey')">
            <label for="kibigXmlImportKey">KiBiG XML-Import-Key</label>
            <md-input name="kibigXmlImportKey" id="kibigXmlImportKey" autocomplete="off"
                      v-model="kibigData.kibigXmlImportKey" :disabled="sending"/>
            <span class="md-error" v-if="!$v.kibigData.kibigXmlImportKey.required">KiBiG XML-Import-Key ist notwendig</span>
          </md-field>
        </div>
        <div class="cell large-3 medium-6 small-12">
          <md-field :class="getValidationClass('maxAllowedChildren')">
            <label for="maxAllowedChildren">Maximale Anzahl Kinder</label>
            <md-input type="number" name="maxAllowedChildren" id="maxAllowedChildren" autocomplete="off"
                      v-model="kibigData.maxAllowedChildren" :disabled="sending"/>
            <span class="md-error"
                  v-if="!$v.kibigData.maxAllowedChildren.required">Maximale Anzahl Kinder ist notwendig</span>
          </md-field>
        </div>
        <div class="cell large-3 medium-6 small-12">
          <md-field :class="getValidationClass('factorPlusX')">
            <label for="factorPlusX">Faktor Plus X</label>
            <md-input type="number" name="factorPlusX" id="factorPlusX" autocomplete="off"
                      v-model="kibigData.factorPlusX" :disabled="sending"/>
            <span class="md-error" v-if="!$v.kibigData.factorPlusX.required">Faktor Plus X ist notwendig</span>
          </md-field>
        </div>
        <div class="cell large-3 medium-6 small-12">
          <md-field :class="getValidationClass('plaetzeLautBetriebserlaubnis')">
            <label for="plaetzeLautBetriebserlaubnis">Plätze laut Betriebserlaubnis</label>
            <md-input type="number" name="plaetzeLautBetriebserlaubnis" id="plaetzeLautBetriebserlaubnis"
                      autocomplete="off"
                      v-model="kibigData.plaetzeLautBetriebserlaubnis" :disabled="sending"/>
            <span class="md-error"
                  v-if="!$v.kibigData.plaetzeLautBetriebserlaubnis.required">Plätze laut Betriebserlaubnis ist notwendig</span>
          </md-field>
        </div>
        <div class="cell large-3 medium-6 small-12">
          <md-field :class="getValidationClass('plaetzeU3')">
            <label for="plaetzeU3">Plätze unter 3 Jahre</label>
            <md-input type="number" name="plaetzeU3" id="plaetzeU3"
                      autocomplete="off"
                      v-model="kibigData.plaetzeU3" :disabled="sending"/>
            <span class="md-error"
                  v-if="!$v.kibigData.plaetzeU3.required">Plätze unter 3 Jahre ist notwendig</span>
          </md-field>
        </div>
        <div class="cell large-3 medium-6 small-12">
          <md-field :class="getValidationClass('plaetze3bisEinschulung')">
            <label for="plaetze3bisEinschulung">Plätze ab 3 Jahre bis Einschulung</label>
            <md-input type="number" name="plaetze3bisEinschulung" id="plaetze3bisEinschulung"
                      autocomplete="off"
                      v-model="kibigData.plaetze3bisEinschulung" :disabled="sending"/>
            <span class="md-error"
                  v-if="!$v.kibigData.plaetze3bisEinschulung.required">Plätze ab 3 Jahre bis Einschulung ist notwendig</span>
          </md-field>
        </div>
        <div class="cell large-3 medium-6 small-12">
          <md-field :class="getValidationClass('plaetzeSchulkinder')">
            <label for="plaetzeSchulkinder">Plätze Schulkinder</label>
            <md-input type="number" name="plaetzeSchulkinder" id="plaetzeSchulkinder"
                      autocomplete="off"
                      v-model="kibigData.plaetzeSchulkinder" :disabled="sending"/>
            <span class="md-error"
                  v-if="!$v.kibigData.plaetzeSchulkinder.required">Plätze Schulkinder ist notwendig</span>
          </md-field>
        </div>
        <div class="cell large-3 medium-6 small-12">
          <h5 class="md-label">Plätze gesamt: {{Number(kibigData.plaetzeU3) + Number(kibigData.plaetze3bisEinschulung) + Number(kibigData.plaetzeSchulkinder)}} <b></b></h5>
        </div>
        <div class="cell large-3 medium-6 small-12">
        </div>
      </div>
    </md-tab>
    <md-tab v-if="false" md-label="Import">
      <div v-if="facilityReferenceNo" class="grid-x grid-padding-x">
        <div class="cell large-6 medium-12 small-12">
          <p><b class="md-title">Daten-Import aus KiBiG-Web exportierter Excel-Datei</b></p>
          <p style="text-align: justify">Hier haben Sie die Möglichkeit Ihre Daten aus KiBiG-Web zu importieren.
            Dies ermöglicht ihnen einen leichten Einstieg für die Nutzung von <b>SoSE</b>.<br><br>
            Bitte beachten Sie, dass Sie hierzu eine EXCEL-Datei benötigen, welche durch einen Datenexport aus dem KiBiG-Web erstellt werden kann.
          </p>
          <br>
        </div>
        <div class="cell large-6 medium-12 small-12">
          <div class="grid-x grid-padding-x">
            <div class="cell small-1"></div>
            <div class="cell small-4">
              <div v-if="uploading">
                <vue-simple-spinner></vue-simple-spinner>
              </div>
              <div v-else>
                <md-field>
                  <label>Jahr</label>
                  <md-select v-model="selectedYear" :disabled="sending" @md-selected="buildUploadUrl">
                    <md-option v-for="year of importYears" :value="year" :key="year">
                      {{year}}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
            <div class="cell small-1"></div>
            <div class="cell small-6 text-center">
              <br>
              <div @click="onVdropzoneClick">
                <vue-dropzone v-if="options.url" ref="myVueDropzone" id="kibibFileDropzone"
                              :options="options"
                              @vdropzone-error="onVdropzoneError"
                              @vdropzone-success="onVdropzoneSuccess"
                              @vdropzone-mounted="onVdropzoneMounted"
                              @vdropzone-processing="onVdropzoneProcessing"
                              :disabled="uploading">
                </vue-dropzone>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <br>
        <ImportNotPossibleCard alternative-text="Für den Daten-Import muss zunächst eine Einrichtung angelegt sein"></ImportNotPossibleCard>
      </div>
    </md-tab>
  </md-tabs>
</template>

<script>

  import jQuery from 'jquery';
  import vue2Dropzone from 'vue2-dropzone';
  import Spinner from 'vue-simple-spinner';
  import HttpInterceptor from '../../services/HttpInterceptor';
  import HttpErrorHandler from '../../services/HttpErrorHandler';
  import Vue from 'vue';
  import ImportNotPossibleCard from "../cards/ImportNotPossibleCard";

  import {validationMixin} from 'vuelidate'
  import {
    required,
  } from 'vuelidate/lib/validators'

  export default {
    name: 'KibigDataForm',
    mixins: [validationMixin],

    components: {
      vueDropzone: vue2Dropzone,
      'vue-simple-spinner': Spinner,
      ImportNotPossibleCard,
    },

    props: {
      kibigData: {
        required: true
      },
      sending: {
        required: true
      },
      facilityReferenceNo: {
        required: true
      },
    },

    created() {
      this.initSyncHandler(true);
    },

    beforeDestroy() {
      this.initSyncHandler(false)
    },

    mounted() {
      this.initData();
    },

    watch: {
      facilityReferenceNo() {
        this.buildUploadUrl();
      },
    },

    data: () => ({
      invalid: true,
      uploading: false,
      selectedYear: 2019,
      importYears: [],
      options: {
        url: '',
        maxFilesize: 0.5, // 500 KB
        headers: {
          'accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'Authorization': 'Bearer ' + sessionStorage.getItem('sose.access_token')
        },
        createImageThumbnails: false,
        thumbnailWidth: 250,
        thumbnailHeight: 50,
        previewTemplate: '<div class="dz-preview dz-file-preview"></div>',
        dictDefaultMessage: 'KiBiG-Datei auswählen',
        acceptedFiles: '.xls, .xlsx',
      },
      openingDays: ['MO', 'DI', 'MI', 'DO', 'FR', 'SA', 'SO'],

      languages: [],
      selectedNativeLanguage: null,
    }),

    validations: {
      kibigData: {
        factorPlusX: {
          required,
        },
        maxAllowedChildren: {
          required,
        },
        plaetzeLautBetriebserlaubnis: {
          required,
        },
        plaetzeU3: {
          required,
        },
        plaetze3bisEinschulung: {
          required,
        },
        plaetzeSchulkinder: {
          required,
        },
        kibigFacilityNumber: {
          required,
        },
        kibigXmlImportKey: {
          required,
        },
      },
    },

    methods: {

      initSyncHandler(init) {
        if (init) {
          setTimeout(() => {
            jQuery('#native-language-id').children('.md-menu').children('input').bind('focus', () => {
              setTimeout(() => jQuery('#native-language-id').children('.md-menu').children('input').trigger('click'), 250);
            });
          }, 500);
        } else {
          jQuery('#native-language-id').children('input').unbind('focus');
        }
      },

      getDayClass(day) {
        if (this.kibigData.openingDays.indexOf(day) >= 0) {
          return 'md-fab md-mini md-primary';
        }
        return 'md-fab md-mini md-plain';
      },

      getOpenState(day) {
        if (this.kibigData.openingDays.indexOf(day) >= 0) {
          return 'geöffnet';
        }
        return 'geschlossen';
      },

      toggleOpenDay(day) {
        let index = this.kibigData.openingDays.indexOf(day);
        if (index > -1) {
          this.kibigData.openingDays.splice(index, 1);
        } else {
          this.kibigData.openingDays.push(day);
        }
      },

      getLanguages(searchTerm) {
        this.languages = new Promise(resolve => {
          window.setTimeout(() => {
            if (!searchTerm) {
              resolve(this.labels.availableLanguageEntries)
            } else {
              const term = searchTerm.toLowerCase();
              resolve(this.labels.availableLanguageEntries.filter(({name}) => name.toLowerCase().includes(term)))
            }
          }, 5)
        })
      },

      selectLanguage(language) {
        this.selectedNativeLanguage = language.name;
        this.kibigData.bilingualLanguage = language.id;
      },

      initData() {
        this.buildUploadUrl();
        this.selectedYear = Vue.moment().format('YYYY');
        if (this.importYears.length <= 0) {
          for (let i = 0; i < 11; i++) {
            this.importYears.push(Vue.moment('01-01-' + String(Number(this.selectedYear) - i), 'DD-MM-YYYY').format('YYYY'));
          }
        }
      },

      buildUploadUrl() {
        this.options.url = '';
        setTimeout(() => this.options.url = this.$store.getters.serverUrl + 'facility/kibig/import/' + this.facilityReferenceNo + '/' + this.selectedYear, 500);
      },

      getValidationClass(fieldName) {
        let field = this.$v.kibigData[fieldName];

        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },

      validateKibigData() {
        this.$v.$touch();
        this.invalid = this.$v.$invalid;
      },

      onVdropzoneMounted() {
        jQuery('#kibibFileDropzone').removeClass().addClass('button');
      },

      onVdropzoneProcessing(file) {
        HttpInterceptor.doRefreshToken();
        this.options.headers.Authorization = 'Bearer ' + sessionStorage.getItem('sose.access_token');
        this.uploading = file.processing;
      },

      onVdropzoneClick() {
        HttpInterceptor.doRefreshToken();
        this.options.headers.Authorization = 'Bearer ' + sessionStorage.getItem('sose.access_token');
      },

      onVdropzoneError(file, message) {
        let errMsg = 'Fehler beim Laden der KiBiG-Datei: <b>' + file.upload.filename + '</b><br>';
        if (message.status) {
          errMsg += '<br><b>' + message.status + '</b>';
        }
        if (message.error) {
          errMsg += ' ' + message.error;
        }
        if (message.message) {
          errMsg += '<br>' + message.message;
        }

        HttpErrorHandler.handleError(null, this, errMsg);
        this.uploading = false;

        if (message.error === 'invalid_token') {
          HttpInterceptor.forceLogout();
        }
      },

      onVdropzoneSuccess(file, response) {
        this.uploading = false;
        if (response.success) {
          this.$store.commit('successMsg', 'Die KiBiG-Datei <b>' + file.upload.filename + '</b> wurde erfolgreich importiert.');
        }
      },
    },

    computed: {
      labels() {
        return this.$store.getters.labels
      },

      isAdmin() {
        return this.$store.getters.admin;
      },
    }
  };
</script>

<style lang="scss" scoped>
  .button {
    margin-bottom: 0;
  }

  .md-tooltip {
    font-size: medium;
    white-space: normal;
  }

  .md-button.md-theme-default {
    border-radius: 50%;
  }

  .md-button.md-theme-dark {
    border-radius: 50%;
  }

  .md-label {
    font-size: medium;
    font-weight: normal;
  }

  .md-icon-label {
    font-size: small;
    margin: 0;
  }

  .md-button.md-theme-default.md-fab.md-plain,
  .md-button.md-theme-dark.md-fab.md-plain {
    background-color: lightgrey;
  }

  .md-checkbox {
    margin: 7px;
  }
</style>
