export default {

  name: 'KibigData',

  kibigData: {
    factorPlusX: 0,
    kibigFacilityNumber: '',
    kibigXmlImportKey: '',
    maxAllowedChildren: 43,
    useAssumptionDay: true,
    usePeaceFestival: false,
    kleineLandgemeinde: false,
    integrativeEinrichtung: false,
    kibigCredentialsStored: false,
    einzelintegration: false,
    schwerpunktKita: false,
    bilingual: false,
    bilingualLanguage: '',
    oeffnungMind45Stunden: false,
    openingDays: ['MO', 'DI', 'MI', 'DO', 'FR'],
    plaetzeLautBetriebserlaubnis: 0,
    plaetze3bisEinschulung: 0,
    plaetzeSchulkinder: 0,
    plaetzeU3: 0,
    summePlaetze: 0,
    schliesstage: 0,
  }
}